var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"barrne"},[_c('img',{attrs:{"src":require("../../assets/ProductIntroductionimg/banner.png"),"alt":"","width":"100%"}}),_c('div',{staticClass:"barrneLeft"},[_vm._m(0),_c('div',[_c('div',{staticClass:"botCls",staticStyle:{"cursor":"pointer"},on:{"click":_vm.pageHref}},[_vm._v(" 免费申请试用 ")]),_c('div',{staticClass:"barrneTile"},[_vm._v(" 金指云是一款更懂粉末成型应用产业精益生产的数字化产品，为企业数字化转型助力 ")])])]),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"form-box"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"fromCls"},[_c('div',{staticClass:"big-title"},[_vm._v("预约产品演示")]),_c('div',{staticClass:"form-div-box"},[_c('div',{staticClass:"form-div-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],attrs:{"placeholder":"请填写公司名称"},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"placeholder":"请填写您的称呼"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"placeholder":"请填写您的手机号"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}}),_c('div',{staticClass:"form-div-submit",on:{"click":_vm.getFromList}},[_vm._v("提交")])]),_vm._m(7)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slogan"},[_c('div',{staticClass:"title_Cls"},[_c('div',{staticClass:"content"},[_vm._v("金指云")]),_c('div',[_c('img',{attrs:{"src":require("../../assets/smartHomeImg/title.png"),"alt":""}})])]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"sort"},[_vm._v("轻量")]),_c('div',{staticClass:"segmentation"}),_c('div',{staticClass:"sort"},[_vm._v("执行")]),_c('div',{staticClass:"segmentation"}),_c('div',{staticClass:"sort"},[_vm._v("追溯")]),_c('div',{staticClass:"segmentation"}),_c('div',{staticClass:"sort"},[_vm._v("透明")]),_c('div',{staticClass:"segmentation"}),_c('div',{staticClass:"sort"},[_vm._v("决策")])]),_c('div',{staticClass:"theme"},[_vm._v(" 以数字化创新技术服务社会、改变世界，以智能力量推动粉末成型应用"),_c('br'),_vm._v(" 行业数字化转型，助力构建数字化的智能世界 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suspensionRight"},[_c('div',{staticClass:"twoDimensional"},[_c('img',{attrs:{"src":require("../../assets/img/erweima.png"),"alt":""}}),_c('div',{staticClass:"tips"},[_vm._v("扫一扫获取专属顾问")])]),_c('div',{staticClass:"tryCls"},[_vm._v(" 申请使用 ")]),_c('div',{staticClass:"connectionCls"},[_vm._v(" 联系客服 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"painPoint"},[_c('div',[_c('div',{staticClass:"painPoint_one"},[_vm._v("行业痛点")]),_c('div',{staticClass:"painPoint_two"},[_vm._v("深挖粉末成型应用产业数字化转型痛点，助力企业数字化快速转型")]),_c('div',[_c('img',{staticClass:"painPoint_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组1(1).png"),"alt":""}}),_c('img',{staticClass:"painPoint_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组2(1).png"),"alt":""}}),_c('img',{staticClass:"painPoint_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组3(1).png"),"alt":""}}),_c('img',{staticClass:"painPoint_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组4(1).png"),"alt":""}}),_c('img',{staticClass:"painPoint_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组5(1).png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"option"},[_c('div',[_c('div',{staticClass:"option_one"},[_vm._v("解决方案")]),_c('div',{staticClass:"option_two"},[_vm._v("金指云是一款更懂粉末成型应用产业精益生产的数字化产品")]),_c('div',[_c('img',{staticClass:"option_img",attrs:{"src":require("../../assets/ProductIntroductionimg/架构.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"applyCLs"},[_c('div',[_c('div',{staticClass:"title"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/ProductIntroductionimg/应用.png"),"alt":""}}),_c('span',{staticClass:"title_head"},[_vm._v("场景应用")])]),_c('div',{staticClass:"title_content"},[_vm._v("以数字化创新技术服务社会、改变世界，以智能力量推动整个行业数字化转型，助力构建万物互联的智能世界")])]),_c('div',[_c('img',{staticClass:"applyCLs_img",attrs:{"src":require("../../assets/ProductIntroductionimg/场景.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"applicationValueCls"},[_c('div',{staticClass:"center-div"},[_c('div',{staticClass:"valueCls"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/ProductIntroductionimg/应用.png"),"alt":""}}),_c('span',{staticClass:"value_head"},[_vm._v("应用价值")])]),_c('div',{staticClass:"value_content"},[_vm._v("助力企业降低成本，提高效率效益明显")])]),_c('div',[_c('div',{staticClass:"value_bottom"},[_c('img',{staticClass:"value_marg_right value_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组1.png"),"alt":""}}),_c('img',{staticClass:"value_marg_right value_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组2.png"),"alt":""}}),_c('img',{staticClass:"value_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组3.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"value_marg_right value_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组4.png"),"alt":""}}),_c('img',{staticClass:"value_marg_right value_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组5.png"),"alt":""}}),_c('img',{staticClass:"value_img",attrs:{"src":require("../../assets/ProductIntroductionimg/组6.png"),"alt":""}})])]),_c('p',{staticClass:"info-text"},[_vm._v("特别提醒：本数据基于我司案例调研所得，各项目实际应用情况有所差异，具体以实际情况为准")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flowCls"},[_c('div',[_c('div',{staticClass:"flowCls_one"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/ProductIntroductionimg/应用.png"),"alt":""}}),_c('span',{staticClass:"flowCls_two"},[_vm._v("实施流程")])]),_c('div',{staticClass:"flowCls_there"},[_vm._v("以数字化创新技术服务社会、改变世界，以智能力量推动整个行业数字化转型，助力构建万物互联的智能世界")])]),_c('div',[_c('img',{staticClass:"flowCls_img",attrs:{"src":require("../../assets/ProductIntroductionimg/流程.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"erweima-box"},[_c('img',{staticClass:"imgCls",attrs:{"src":require("../../assets/img/erweima.png"),"alt":""}}),_c('div',{staticClass:"erweima-title"},[_vm._v("微信扫码获取专属顾问")])])
}]

export { render, staticRenderFns }