<template>
    <div>

        <div  class="barrne">
            <img src="../../assets/ProductIntroductionimg/banner.png" alt="" width="100%">

            <div class="barrneLeft">
              <div class="slogan">
                <div class="title_Cls">
                  <div class="content">金指云</div>
                  <div>
                    <img src="../../assets/smartHomeImg/title.png" alt="">
                  </div>
                </div>
                <div style=" display: flex;align-items: center;">
                  <div class="sort">轻量</div>
                  <div class="segmentation"></div>
                  <div class="sort">执行</div>
                  <div class="segmentation"></div>
                  <div class="sort">追溯</div>
                  <div class="segmentation"></div>
                  <div class="sort">透明</div>
                  <div class="segmentation"></div>
                  <div class="sort">决策</div>
                </div>
                <div class="theme">
                  以数字化创新技术服务社会、改变世界，以智能力量推动粉末成型应用<br>
                  行业数字化转型，助力构建数字化的智能世界
                </div>
              </div>
              <div>
                  <div class="botCls" style="cursor:pointer;" @click="pageHref">
                      免费申请试用
                  </div>
                  <div class="barrneTile">
                      金指云是一款更懂粉末成型应用产业精益生产的数字化产品，为企业数字化转型助力
                  </div>
              </div>
            </div>

            <!-- 右侧悬浮界面区域 -->
            <div class="suspensionRight">
                <div class="twoDimensional" >
                    <img src="../../assets/img/erweima.png" alt="">
                    <div class="tips">扫一扫获取专属顾问</div>
                </div>
                <div class="tryCls">
                    申请使用
                </div>
                <div class="connectionCls">
                    联系客服
                </div>
            </div>
        </div>

        <div class="painPoint">
            <div>
                <div class="painPoint_one">行业痛点</div>
                <div class="painPoint_two">深挖粉末成型应用产业数字化转型痛点，助力企业数字化快速转型</div>
                <div>
                    <img src="../../assets/ProductIntroductionimg/组1(1).png" alt="" class="painPoint_img">
                    <img src="../../assets/ProductIntroductionimg/组2(1).png" alt="" class="painPoint_img">
                    <img src="../../assets/ProductIntroductionimg/组3(1).png" alt="" class="painPoint_img">
                    <img src="../../assets/ProductIntroductionimg/组4(1).png" alt="" class="painPoint_img">
                    <img src="../../assets/ProductIntroductionimg/组5(1).png" alt="" class="painPoint_img">
                </div>
            </div>
        </div>


        <div class="option">
            <div>
                <div class="option_one">解决方案</div>
                <div class="option_two">金指云是一款更懂粉末成型应用产业精益生产的数字化产品</div>
                <div>
                    <img src="../../assets/ProductIntroductionimg/架构.png" alt="" class="option_img">
                </div>
            </div>
        </div>

        <div  class="applyCLs">
            <div>
                <div  class="title">
                  <div>
                    <img src="../../assets/ProductIntroductionimg/应用.png" alt="">
                    <span  class="title_head">场景应用</span>
                  </div>
                  <div  class="title_content">以数字化创新技术服务社会、改变世界，以智能力量推动整个行业数字化转型，助力构建万物互联的智能世界</div>
                </div>
                <div>
                    <img src="../../assets/ProductIntroductionimg/场景.png"  alt="" class="applyCLs_img" >
                </div>
            </div>
        </div>


        <div class="applicationValueCls">
            <div class="center-div">
                <div class="valueCls">
                    <div>
                        <img src="../../assets/ProductIntroductionimg/应用.png" alt="" >
                        <span  class="value_head">应用价值</span>
                    </div>
                    <div class="value_content">助力企业降低成本，提高效率效益明显</div>
                </div>


                <div>
                  <div  class="value_bottom">
                    <img src="../../assets/ProductIntroductionimg/组1.png" alt=""  class="value_marg_right value_img">
                    <img src="../../assets/ProductIntroductionimg/组2.png" alt="" class="value_marg_right value_img">
                    <img src="../../assets/ProductIntroductionimg/组3.png" alt="" class="value_img">
                  </div>
                  <div>
                      <img src="../../assets/ProductIntroductionimg/组4.png" alt="" class="value_marg_right value_img">
                    <img src="../../assets/ProductIntroductionimg/组5.png" alt="" class="value_marg_right value_img">
                    <img src="../../assets/ProductIntroductionimg/组6.png" alt="" class="value_img">
                 </div>
                </div>
                <p class="info-text">特别提醒：本数据基于我司案例调研所得，各项目实际应用情况有所差异，具体以实际情况为准</p>
            </div>
        </div>
        <div class="flowCls">
            <div>
                <div class="flowCls_one">
                    <div>
                        <img src="../../assets/ProductIntroductionimg/应用.png" alt="" >
                        <span class="flowCls_two">实施流程</span>
                    </div>
                    <div class="flowCls_there">以数字化创新技术服务社会、改变世界，以智能力量推动整个行业数字化转型，助力构建万物互联的智能世界</div>
                </div>
                <div>
                    <img src="../../assets/ProductIntroductionimg/流程.png" alt="" class="flowCls_img">
                </div>
            </div>
        </div>

        <div class="form-box" >
        <div class="box">
            <div class="fromCls">
                <div class="big-title">预约产品演示</div>
                <div class="form-div-box">
                <div class="form-div-input">
                    <input placeholder="请填写公司名称" v-model="company">
                    <input placeholder="请填写您的称呼" v-model="name">
                    <input placeholder="请填写您的手机号" v-model="phone">
                    <div class="form-div-submit" @click="getFromList">提交</div>
                </div>
                <div class="erweima-box">
                    <img src="../../assets/img/erweima.png" alt="" class="imgCls">
                    <div class="erweima-title">微信扫码获取专属顾问</div>
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return{
      company: '',
      city: '',
      industry: '',
      name: '',
      phone: '',
      demand: '',
      one: '',
      checkedNames: '数字化咨询'
    }
  },
  methods: {
    pageHref() {
      this.$router.push({
        path: '/intelligent/about',
        query: {
          vs: 1
        }
      })
    },
    getFromList() {
      if (this.company !== '' && this.name !== '' && this.phone !== '') {
        axios.get('/Finance/Clue!saveOfficialWebsiteClues.action', {
          params: {
            company: this.company,
            name: this.name,
            telephone: this.phone
          }
        }).then(res => {
          if (res.status === 200) {
            this.$message({
              message: '提交成功!',
              type: 'success'
            });
          }
          this.company = ''
          this.city = ''
          this.industry = ''
          this.name = ''
          this.phone = ''
          this.demand = ''
        })
      } else {
        this.$message.error('请填写完公司名称,姓名,联系电话!')
      }
    },
    viewMore() {
      this.$router.push('/intelligent/about')
    }
  }
}
</script>
<style lang="scss" scoped>

.barrne{
  height: 560px;
  width: 100%;
  position: relative;
  .barrneLeft{
    position: absolute;
    top: 206px;
    left: 360px;

    .slogan {
      .title_Cls {
        display: flex;
        align-items: center;
        margin-bottom: 21px;

        img {
          width: 317px;
        }
      }

      .content {
        margin-left: 5px;
        color: #FFF;
        font-weight: bold;
        font-size: 52px;
        padding-right: 20px;
      }

      .sort {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 32px;
      }

      .segmentation {
        width: 3px;
        height: 30px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: #FFFFFF;
      }

      .theme {
        margin-top: 21px;
        margin-bottom: 24px;
        line-height: 1.5;
        font-weight: 400;
        color: #FFFFFF;
        font-size: 20px;
      }
    }
  }
  .barrneTile{
    font-size: 14px;
    color: #fff;
  }
  .suspensionRight{
    position: absolute;
    right: 17px;
    top:199px
  }
  .twoDimensional{
    width:118px;
    padding:5px;
    border-radius: 6px;
    background:#FFF;
    text-align:center;
    margin-bottom: 2px;
    box-shadow: 0px 12px 20px 1px rgba(149,100,36,0.08);
    img {
    width: 85px;
    height: 85px;
  }
  }

  .tips{
    font-size: 8px;
    color: #000000;
  }
}



.botCls {
    margin-top: 48px;
    margin-bottom: 16px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 48px;
    text-align: center;
    width: 276px;
    height: 48px;
    background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
    box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.39);
    border-radius: 4px;
}

.tryCls {
    line-height: 30px;
    text-align: center;
    margin-bottom: 2px;
    font-size: 14px;
    color: #FFFFFF;
    width: 118px;
    height: 30px;
    background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
    box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.26);
    border-radius: 4px;
}

.connectionCls {
    text-align: center;
    line-height: 30px;
    color: #111111;
    font-size: 14px;
    width: 118px;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 1px rgba(206, 104, 0, 0.26);
    border-radius: 4px;
}

.painPoint{
  height: 500px;
  background: #F3F8FF;
  display: flex;
  justify-content: center;
  align-items: center;
  .painPoint_one{
    width: 100%;
    text-align: center;
    font-size: 36px;
    color: #111111;
    margin-bottom: 20px;
   font-weight: bold;
  }
  .painPoint_two{
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #111111;
    margin-bottom: 10px;
  }
  .painPoint_img{
    width: 230px;
    height: 230px;
  }
}

.option{
  display: flex;
  justify-content: center;
  align-items: center;
  .option_one{
    font-weight: bold;
    width: 100%;
    text-align: center;
    font-size: 36px;
    color: #111111;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .option_two{
    font-weight: bold;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #111111;
    margin-bottom: 10px;
  }
  .option_img{
    width: 1200px;
    height: 440px;
  }
}

.applyCLs{
  display: flex;
  justify-content: center;
  align-items: center;
   margin-top: 41px;
   margin-bottom: 73px;
   .title{
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    img{
      width: 36px;
      height: 36px;
    }
     .title_head{
      font-size: 36px;
      color: #111111;
      font-weight: bold;
      margin-left: 10px;
      margin-right: 15px;
     }
     .title_content{
      color: #666666;
      font-size: 18px;
      font-weight: bold;
     }
   }
   .applyCLs_img{
      width: 1200px;
      height: 710px;
   }
}

.applicationValueCls{
    height: 604px;
    background-image: url('../../assets/ProductIntroductionimg/bg.png');
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .valueCls{
      display: flex;
      align-items: center;
      margin-bottom: 49px;
      img{
        width: 36px;
         height: 36px;
      }
      .value_head{
        font-weight: bold;
        font-size: 36px;
        color: #111111;
        margin-left: 10px;
        margin-right: 15px;
      }
      .value_content{
        font-size: 18px;
        color: #666666;
        font-weight: bold;
      }
    }
    .value_bottom{
      margin-bottom: 20px;
    }
    .value_img{
      width: 390px;
      height: 175px;
    }
     .value_marg_right{
      margin-right: 15px;
     }

}

.flowCls{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 58px;
  margin-bottom: 58px;
  .flowCls_one{
    display: flex;
    align-items: center;
    margin-bottom: 49px;
    margin-top: 68px;
  }
  img{
    width: 36px;
      height: 36px;
  }
  .flowCls_two{
    font-weight: bold;
    font-size: 36px;
    color: #111111;
    margin-left: 10px;
    margin-right: 15px;
  }
  .flowCls_there{
    font-size: 18px;
    color: #666666;
    font-weight: bold;
  }
  .flowCls_img{
   width: 1164px;
   height: 302px;
  }
}




.form-box{
  background: url('../../assets/img/form-bg.png');
  background-size: 100% 100%;
  height: 417px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}
.box{
  width: 1424px;
  margin: 0 auto;
}
.fromCls{
  // width: 590px;
  // height: 312px;
  // background: #FFFFFF;
  // border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding-left: 150px;
  padding-top: 74px;
}
.big-title{
  // height: 36px;
  font-size: 36px;
  font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
  font-weight: bold;
  color: #111111;
  padding-left: 120px;
}
.form-div-box{
  display: flex;
  align-items: center;
  .form-div-input{
    display: flex;
    flex-direction: column;
    input{
      outline-color: #FFFFFF;
     font-size: 16px;
      width: 331px;
      height: 36px;
      background: #FFFFFF;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 0;
      margin-top: 15px;
      padding: 0 10px;
    }
  }
  .form-div-submit{
    cursor: pointer;
    width: 175px;
    height: 36px;
    background: linear-gradient(135deg, #FCA800 0%, #E63F00 100%);
    box-shadow: 0px 4px 14px 1px rgb(206 104 0 / 39%);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    /* line-height: 0px; */
    text-align: center;
    line-height: 36px;
    margin-top: 25px;
  }
  .erweima-box{
    margin-left: 30px;
    background-color: #FFFFFF;
    padding: 10px;
    text-align: center;
    .erweima-title{
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #A5B7CF;
    }
  }
}
.imgCls{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 138px;
  height: 134px;
}
.center-div{
  text-align: center;
}
.info-text{
  text-align: right; 
  margin-top: 5px; 
  color: #737272;
  font-size: 14px;
}
</style>
